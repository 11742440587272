//====================== v3
.hot-page_wrapper {
  display: grid;
  grid-template-columns: 0.2fr 0.6fr 0.2fr;
  // grid-column-start: 1;
  // grid-area: 0 1 2 1;
  // height: calc((100% - 15px));
  width: 100%;
  height: 100%;
}

.swiper {
  width: 100%;
}

.hot_group_container {
  a {
    display: contents;
  }

  display: grid;
  padding: 0 5px;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 5px;
}

.group_hot_item {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  .image_wrapper {
    position: relative;
    overflow: hidden;
    object-fit: contain;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: initial;

    height: 100%;
    margin: auto 0;

    width: 100%;
    background-color: black;

    & img {
      display: block;

      position: absolute;
      // left: 0;
      top: 50%;
      left: 50%;
      width: 140%;

      // transform: translateY(-50%);
      // transform: translateX(-50%);
      transform: translate(-50%, -50%);
    }
  }

  .image_wrapper::after {
    display: block;
    content: "";
    height: 100%;
    padding-bottom: 10%;
  }

  .description {
    height: auto;
    min-height: 50px;
    background-color: #fff;
    color: #000;

    font-size: 2.4vmin;
    font-weight: bold;
    padding: 5px;
  }
}
