@import "../Styles/variables.scss";

.catalog {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: auto 0.8fr;
  height: 100vh;
  width: 100vw;

  button,
  a {
    color: white;
    font-size: 1.6vmin;
  }

  &-control {
    display: flex;
    grid-column: span 2;
    justify-content: space-between;

    // min-height: 56px;

    div:first-child {
      // flex-grow: 1;
      display: flex;
      justify-items: flex-start;
      align-items: center;
    }

    div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      height: 100%;
      background: none;
      border: none;
      font-size: 24pt;
      padding: 0 18px;
      line-height: 0;
      text-transform: uppercase;
    }
    h4 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      // padding: 18px;
    }
  }

  .catalog_title {
    grid-column: span 2;
    font-size: 30px;
    font-weight: bold;
    padding: 12px 0;
  }

  &-menu {
    overflow-y: auto;

    &.visible {
      min-width: 320px;
    }

    /* hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    /* /hide scrollbar */

    a {
      min-width: 120px;

      display: block;
      padding: 1rem 1rem;

      font-size: 1.4rem;
    }

    .active {
      background-color: $active_color;
      // color: black;
    }
  }

  .catalog_content_wrapper {
    height: 100%;

    display: grid;
    grid-template-rows: auto 1fr;
  }

  &-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 5px;

    &__image {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-title {
      padding: 0.3rem 0.5rem;
      font-size: 24pt;
      color: black;
      background-color: rgba($color: white, $alpha: 1);

      h4 {
        padding-bottom: 0.3rem;
        font-weight: 500;
        @media only screen and (max-width: 600px) {
          font-size: 6pt;
        }
        @media only screen and (min-width: 600px) {
          font-size: 10pt;
        }
        @media only screen and (min-width: 768px) {
          font-size: 12pt;
        }
        @media only screen and (min-width: 992px) {
          font-size: 16pt;
        }
        @media only screen and (min-width: 1200px) {
          font-size: 18pt;
        }
      }
    }

    &-price {
      position: relative;
      padding-top: 0.5rem;
      font-size: 2.4vmin;
      font-weight: bold;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 2px;

        border-bottom: 1px dashed black;
      }
    }
    &-weight {
      font-size: 2vmin;
      font-style: italic;
    }
    &-description {
      font-size: 2vmin;
    }
  }

  .catalog-control_inner {
    height: 100%;

    .catalog-control_btn_wrapper {
      padding: 12px;
      svg {
        width: 6vh;
        height: auto;
        fill: #fff;
      }
    }

    .active {
      i {
        color: $active_color;
      }

      svg {
        fill: $active_color !important;
      }
    }
  }

  .stub {
    width: 100%;
    height: 100%;
    display: grid;
    background-image: url("../Images/marble-white.png");
    background-size: cover;

    div {
      background-size: 40%;
      background-position: center;
      background-repeat: no-repeat;
    }

    :first-child {
      background-image: url("../Images/logo.svg");
    }

    :nth-child(2n) {
      background-image: url("../Images/logo-round.svg");
      background-size: contain;
      display: none;
    }

    :last-child {
      background-image: url("../Images/logo-resto.svg");
    }
  }

  .prodict-list-swiper.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .swiper-slide {
      // margin: 0 !important;
      // padding: 0 !important;
      //height: auto !important;
      width: 50% !important;
    }
  }
}

.productCategory-control {
  margin-bottom: 10px;

  width: 100vw;

  grid-column: span 2;

  .productCategory-control_item {
    box-sizing: border-box;

    padding: 10px;
    padding-bottom: 12px;

    margin-right: 10px;

    font-size: 2vmin;
    &.active {
      color: $active_color;
    }

    &.active::after {
      content: "";
      position: absolute;
      height: 3px;
      bottom: -1px;
      left: 0;

      border-top: 1px dashed $active_color;
      background-color: black;

      width: 100%;
      z-index: 20;
    }
  }

  // .swiper-wrapper {
  //   .swiper-slide:last-child {
  //     .productCategory-control_item {
  //       margin-right: 0;
  //     }
  //   }
  // }
}

.logo_wrapper {
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 100%;
  // background-color: #fff;
  padding: 5px;

  // svg {
  //   fill: #fff !important;
  //   filter: invert(100%);
  // }

  .logo {
    width: 20vmin;
    height: 100%;

    background-image: url("/Assets/Images/logo-resto.svg");
    background-repeat: no-repeat;
    background-position: center;

    filter: invert(100%);
  }
}
