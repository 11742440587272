@import "../Styles/variables";

.product {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5vh;

  width: 100%;
  height: 100%;

  z-index: 10;

  background-color: rgba(112, 112, 112, 0.363);
  backdrop-filter: blur(2px);

  box-sizing: border-box;
  .product_popup {
    display: grid;
    grid-template-columns: 0.65fr 0.35fr;

    // padding: 10vw;

    width: 100%;
    height: 100%;

    // background-color: black;
    background-color: #fff;
    color: black;

    border-radius: 10px;
    overflow: hidden;
  }

  .product_swiper_wrapper {
    position: relative;

    width: 100%;
    height: 100%;
  }

  .close_btn {
    position: fixed;
    right: 10px;
    top: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 6vh;
    height: 6vh;

    z-index: 10;
    // background-color: rgba(255, 255, 255, 0.26);
    background-color: $active_color;
    border-radius: 5px;
    img {
      display: block;
      width: 70%;
      height: 70%;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: 100% !important;
  }

  .image {
    display: block;
    margin-left: auto;
    margin-right: auto;

    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: 10px;
  }

  .item_image {
    position: relative;
    overflow: hidden;
    object-fit: contain;

    display: flex;
    justify-content: center;
    align-items: center;
    // min-width: 127px;
    z-index: initial;
    // border-radius: 10px;

    height: 70%;
    margin: auto 0;

    & img {
      display: block;

      position: absolute;
      left: 0;
      top: 50%;

      width: 100%;
      border-radius: 15px;
      transform: translateY(-50%);
    }
  }

  .item_image::after {
    display: block;
    content: "";
    padding-bottom: 10%;
    margin-left: 20%;
  }

  .content {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    // background: url("../Images/logo-round.svg") no-repeat center;
    background-size: 50%;

    // padding: 15px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-large;
    font-weight: 600;

    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
  }

  &-price {
    position: relative;

    padding: 0.5rem 0;

    font-size: 3vmin;
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 63%;
      height: 2px;

      border-bottom: 1px dashed black;
    }
  }

  &-weight {
    font-style: italic;
    font-size: 1.6vmin;
  }

  &-description {
    padding-bottom: 8px;
    font-size: 1.8vmin;
  }

  dl {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;
  }

  .label-item {
    display: flex;
    justify-content: space-between;
  }
}

.price_sign {
  font-weight: normal;
  font-size: 0.8em;
}

.swiper-button-next,
.swiper-button-prev {
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.301);

  color: black !important;
}
