* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
  font-family: "Inter", sans-serif;
  background: #000;
  color: white;
  overscroll-behavior: contain;

  font-size: 1.5vmin;
}

a {
  text-decoration: none;
}

