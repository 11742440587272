/* Generated by Glyphter (http://www.glyphter.com) on  Mon May 16 2022*/
@font-face {
    font-family: 'usadba web';
    src: url('../Fonts/usadba-web.eot');
    src: url('../Fonts/usadba-web.eot?#iefix') format('embedded-opentype'),
         url('../Fonts/usadba-web.woff') format('woff'),
         url('../Fonts/usadba-web.ttf') format('truetype'),
         url('../Fonts/usadba-web.svg#usadba-web') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'usadba web';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-back:before{content:'\0041';}
.icon-gears:before{content:'\0042';}
.icon-home:before{content:'\0043';}
.icon-resize:before{content:'\0044';}

.icon-hot {
    width: 35px;
    height: 32px;
    fill: #FFF;
}