.overlay {
  position: absolute;
  flex-direction: row;
  justify-content: flex-end;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: gray, $alpha: 0.7);

  &-content {
    background: black;
    width: 15vw;

    button {
      padding: 0.5rem;
    }
  }
}
